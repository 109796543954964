import React, { useState } from 'react'
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar } from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { ReactComponent as DeployerLogo } from '../images/deployer-logo.svg'

export default function AppHeader() {
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onSignOutClick = () => {
    handleClose()
    signOut()
  }

  return (
    <AppBar component="header" position="static">
      <Toolbar>
        <DeployerLogo style={{ width: '6.5rem' }} />
        {user && (
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={onSignOutClick}>Sign out</MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}
