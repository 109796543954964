import { gql } from '@apollo/client'
import { RunnerStatus, RunnerStatusList, RunnerStatusLogList } from './types'

export interface GetRunnerStatusesData {
  getRunnerStatuses: RunnerStatusList
}

export interface GetRunnerStatusesVars {
  accountId?: string
  region?: string
  projectName?: string
}

export const GET_RUNNER_STATUSES = gql`
  query getRunnerStatuses($accountId: String, $region: String, $projectName: String) {
    getRunnerStatuses(input: { accountId: $accountId, region: $region, projectName: $projectName }) {
      items {
        Account {
          id
          name
          regions
          type
        }
        Project {
          name
          type
          Repository {
            repositoryUrl
            latestMasterCommitHash
            repositoryUrl
            masterCommits {
              message
              timestamp
              hash
              authorName
              authorEmail
            }
            Images {
              tag
              digest
              timestamp
            }
          }
          LatestImage {
            tag
            digest
            timestamp
          }
        }
        accountId
        logUrl
        projectName
        region
        status
        sourceVersion
        timestamp
      }
    }
  }
`

export interface GetRunnerStatusLogData {
  getRunnerStatusLog: RunnerStatusLogList
}

export interface GetRunnerStatusLogVars {
  accountId: string
  projectName: string
  region: string
  sinceDate: string
}

export const GET_RUNNER_STATUS_LOG = gql`
  query getRunnerStatusLog($accountId: String!, $projectName: String!, $region: String!, $sinceDate: String!) {
    getRunnerStatusLog(
      input: { accountId: $accountId, projectName: $projectName, region: $region, sinceDate: $sinceDate }
    ) {
      items {
        accountId
        projectName
        region
        status
        timestamp
        sourceVersion
        logUrl
      }
    }
  }
`

export interface RunnerStatusUpdatedData {
  runnerStatusUpdated: RunnerStatus
}

export const RUNNER_STATUS_UPDATED = gql`
  subscription runnerStatusUpdated {
    runnerStatusUpdated {
      accountId
      logUrl
      projectName
      region
      status
      sourceVersion
      timestamp
    }
  }
`
