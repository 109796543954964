import React, { useEffect } from 'react'
import { Button, CardActions, CardContent, Grid, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useAppDispatch } from 'src/lib/store'
import { setBreadcrumbs } from 'src/lib/store/features/breadcrumbsSlice'

export default function HomePage() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setBreadcrumbs([]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container spacing={4} alignContent="center">
      <Grid item xs={12} md={6} sx={{ justifyContent: 'center', display: 'flex' }}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Accounts
          </Typography>
          <Typography paragraph>List of AWS account</Typography>
          <CardActions sx={{ justifyContent: 'center', display: 'flex' }}>
            <Button component={RouterLink} to="/accounts" color="secondary" variant="contained">
              Accounts
            </Button>
          </CardActions>
        </CardContent>
      </Grid>
      <Grid item xs={12} md={6} sx={{ justifyContent: 'center', display: 'flex' }}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Projects
          </Typography>
          <Typography paragraph>List of the projects in Brinkcommerce deployer</Typography>
          <CardActions sx={{ justifyContent: 'center', display: 'flex' }}>
            <Button component={RouterLink} to="/projects" color="secondary" variant="contained">
              Projects
            </Button>
          </CardActions>
        </CardContent>
      </Grid>
      <Grid item xs={12} md={6} sx={{ justifyContent: 'center', display: 'flex' }}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Monitoring
          </Typography>
          <Typography paragraph>Basic monitoring</Typography>
          <CardActions sx={{ justifyContent: 'center', display: 'flex' }}>
            <Button component={RouterLink} to="/monitoring" color="secondary" variant="contained">
              Monitoring
            </Button>
          </CardActions>
        </CardContent>
      </Grid>
    </Grid>
  )
}
