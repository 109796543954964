import React from 'react'
import { DateTime } from 'luxon'
import { Tooltip } from '@mui/material'

interface Props {
  timestamp: string
}

export default function Timestamp({ timestamp }: Props) {
  return (
    <Tooltip title={timestamp}>
      <span>{DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_MED)}</span>
    </Tooltip>
  )
}
