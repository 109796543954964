import { gql } from '@apollo/client'
import { Repository } from './types'

export interface GetRepositoryData {
  getRepository: Repository
}

export interface GetRepositoryVars {
  projectName: string
}

export const GET_REPOSITORY = gql`
  query GetRepository($projectName: String!) {
    getRepository(projectName: $projectName) {
      repositoryUrl
      latestMasterCommitHash
      masterCommits {
        hash
        message
        timestamp
        authorName
        authorEmail
      }
      Images {
        tag
        digest
        timestamp
      }
    }
  }
`

export interface RepositoryUpdatedData {
  repositoryUpdated: {
    latestMasterCommitHash: string
    projectName: string
    repositoryUrl: string
    timestamp: string
    masterCommits: {
      hash: string
      message: string
      timestamp: string
      authorName: string
      authorEmail: string
    }[]
  }
}

export const REPOSITORY_UPDATED = gql`
  subscription repositoryUpdated {
    repositoryUpdated {
      latestMasterCommitHash
      projectName
      repositoryUrl
      timestamp
      masterCommits {
        hash
        message
        timestamp
        authorName
        authorEmail
      }
    }
  }
`
