import { CloudWatchClient, GetMetricDataCommand, MetricDataQuery, MetricStat } from '@aws-sdk/client-cloudwatch'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import { Paper, styled } from '@mui/material'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { Account } from 'src/lib/graphql/types'

interface MetricsQueries {
  accounts: Account[]
  metrics: [{ id: string; stats: MetricStat }]
}

export const toMetricsQueries: Function = (queries: MetricsQueries): MetricDataQuery[] => {
  const metricDataQueries: MetricDataQuery[] = []

  if (!queries || !queries.accounts) return metricDataQueries
  queries.accounts.forEach((i) => {
    queries.metrics.forEach((m) => {
      metricDataQueries.push({
        Id: i.name + m.id,
        AccountId: i.id,
        MetricStat: m.stats,
      })
    })
  })
  return metricDataQueries
}

export const queryMetrics: Function = async (metricQueries: MetricsQueries) => {
  const session = await Auth.currentSession()
  const region = process.env.REACT_APP_APPSYNC_REGION || ''
  const client = new CloudWatchClient({
    region: region,
    credentials: credentials(region, session),
  })
  const now = new Date()
  const command = new GetMetricDataCommand({
    StartTime: new Date(new Date().setDate(new Date().getDate() - 1)),
    EndTime: now,
    MetricDataQueries: toMetricsQueries(metricQueries),
    ScanBy: 'TimestampDescending',
  })
  return await client.send(command)
}

const credentials: Function = (region: string, session: CognitoUserSession) => {
  return fromCognitoIdentityPool({
    clientConfig: { region: region },
    identityPoolId: process.env.REACT_APP_IDENTITIY_ID || '',
    logins: {
      [`cognito-idp.${region}.amazonaws.com/${process.env.REACT_APP_USERPOOL_ID || ''}`]: session
        .getIdToken()
        .getJwtToken(),
    },
  })
}

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))
