import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  DialogActions,
  Button,
  Link,
  Tooltip,
  Box,
} from '@mui/material'
import Timestamp from 'src/components/Timestamp'
import { Repository } from 'src/lib/graphql/types'

interface Props {
  open: boolean
  handleClose: () => void
  deployedCommit: string
  repository: Repository
}

const DiffModal = ({ open, handleClose, deployedCommit, repository }: Props) => {
  const repositoryUrl = repository.repositoryUrl ?? ''
  const latestMasterCommitHash = repository.latestMasterCommitHash ?? ''
  const masterCommitHistory = repository.masterCommits ?? []
  const deployedCommitTimestamp =
    masterCommitHistory.find((h) => h.hash === deployedCommit)?.timestamp ?? '2000-01-01T00:00:00Z'
  const deployedCommitDate = new Date(deployedCommitTimestamp)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle textAlign="center">Master commits</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center">
          <Link
            target="_blank"
            rel="noreferrer"
            href={repositoryUrl + '/compare/' + deployedCommit + '...' + latestMasterCommitHash}
          >
            Show diff at GitHub
          </Link>
        </DialogContentText>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Hash</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {masterCommitHistory
              .filter((h) => {
                return new Date(h.timestamp).getTime() > deployedCommitDate.getTime()
              })
              .map((h) => {
                return (
                  <TableRow key={h.hash}>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      <Timestamp timestamp={h.timestamp} />
                    </TableCell>
                    <TableCell>{h.hash?.substring(0, 7)}</TableCell>
                    <TableCell style={{ whiteSpace: 'pre-line' }}>{h.message}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      <Tooltip title={h.authorEmail}>
                        <Box component="span">{h.authorName}</Box>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DiffModal
