import { createSlice } from '@reduxjs/toolkit'

export const alertSlice = createSlice({
  name: 'alert',
  initialState: { open: false, severity: 'success', text: '' },
  reducers: {
    setAlert: (state, action) => {
      return action.payload
    },
  },
})

export const { setAlert } = alertSlice.actions

export default alertSlice.reducer
