import React from 'react'
import { DateTime } from 'luxon'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { Autocomplete, Box, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { Image } from 'src/lib/graphql/types'

export interface AutocompleteTagProps {
  loading: boolean
  disabled: boolean
  tag: TagOption | null
  latestTag?: string
  tags: TagOption[]
  onChange: (t: TagOption | null) => void
}

export interface TagOption extends Image {
  id: string
  label: string
  deployDate: string
}

const filterOptions = createFilterOptions<TagOption>({
  matchFrom: 'start',
  stringify: (option) => option.tag,
})

export default function AutocompleteTag({ loading, disabled, tag, tags, latestTag, onChange }: AutocompleteTagProps) {
  const [tagInputValue, setTagInputValue] = React.useState('')

  if (loading) return <CircularProgress />

  return (
    <Autocomplete
      sx={{ my: 2 }}
      loading={loading}
      disabled={disabled}
      options={tags}
      groupBy={(image: TagOption) => image.deployDate}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={tag}
      onChange={(event, newTag) => onChange(newTag)}
      inputValue={tagInputValue}
      onInputChange={(event, newTagInputValue) => {
        setTagInputValue(newTagInputValue)
      }}
      renderInput={(params) => <TextField {...params} label="Tag" />}
      filterOptions={filterOptions}
      renderOption={(props, to) => {
        const matches = match(to.tag, tagInputValue, { insideWords: false })
        const parts = parse(to.tag, matches)

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {time(to.timestamp)} {to.tag === latestTag ? ' (master)' : ''}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

const time = (timestamp: string) => DateTime.fromISO(timestamp).toLocaleString(DateTime.TIME_SIMPLE)
