import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material'
import {
  GET_RUNNER_STATUSES,
  GetRunnerStatusesData,
  GetRunnerStatusesVars,
  GET_RUNNER_STATUS_LOG,
  GetRunnerStatusLogData,
  GetRunnerStatusLogVars,
} from 'src/lib/graphql/runnerStatuses'
import StatusTableCell from 'src/components/tablecells/StatusTableCell'
import RepositoryTableCell from 'src/components/tablecells/RepositoryTableCell'
import LogUrlTableCell from 'src/components/tablecells/LogUrlTableCell'
import Timestamp from 'src/components/Timestamp'
import { useAppDispatch } from 'src/lib/store'
import { setBreadcrumbs } from 'src/lib/store/features/breadcrumbsSlice'
import { RunnerStatusLog } from 'src/lib/graphql/types'

export default function RunnerPage() {
  const { accountId, region, projectName } = useParams()

  const dispatch = useAppDispatch()
  const location = useLocation()
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { displayName: `Accounts`, pathName: '/accounts' },
        { displayName: `${accountId} / ${region}`, pathName: `/accounts/${accountId}/${region}` },
        { displayName: `Projects / ${projectName}`, pathName: `/projects/${projectName}` },
        { displayName: 'History', pathName: location.pathname },
      ])
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    loading: getRunnerStatusLogLoading,
    data: getRunnerStatusLogData,
    error: getRunnerStatusLogError,
  } = useQuery<GetRunnerStatusLogData, GetRunnerStatusLogVars>(GET_RUNNER_STATUS_LOG, {
    variables: {
      accountId: accountId as string,
      region: region as string,
      projectName: projectName as string,
      sinceDate: '2000',
    },
  })

  const {
    loading: getRunnerStatusesLoading,
    data: getRunnerStatusesData,
    error: getRunnerStatusesLoadingError,
  } = useQuery<GetRunnerStatusesData, GetRunnerStatusesVars>(GET_RUNNER_STATUSES, {
    variables: {
      accountId: accountId as string,
      region: region as string,
      projectName: projectName as string,
    },
  })

  if (getRunnerStatusLogLoading || getRunnerStatusesLoading) {
    return <CircularProgress />
  }

  if (getRunnerStatusLogError || getRunnerStatusesLoadingError || !getRunnerStatusLogData || !getRunnerStatusesData) {
    return <div>Got an error...</div>
  }

  return (
    <>
      <Typography align="center" sx={{ m: 3 }} variant="h5">
        Current status
      </Typography>
      <TableContainer component={Paper} elevation={2}>
        <Table aria-label="accounts table">
          <TableHead>
            <TableRow>
              <TableCell>Project name</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>Repository</TableCell>
              <TableCell>Source Version</TableCell>
              <TableCell>Log url</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{getRunnerStatusesData.getRunnerStatuses.items[0].projectName}</TableCell>
              <TableCell>{getRunnerStatusesData.getRunnerStatuses.items[0].accountId}</TableCell>
              <TableCell>{getRunnerStatusesData.getRunnerStatuses.items[0].region}</TableCell>
              <RepositoryTableCell
                repositoryUrl={getRunnerStatusesData.getRunnerStatuses.items[0].Project.Repository.repositoryUrl}
              />
              <TableCell>{getRunnerStatusesData.getRunnerStatuses.items[0].sourceVersion}</TableCell>
              <LogUrlTableCell logUrl={getRunnerStatusesData.getRunnerStatuses.items[0].logUrl} />
              <TableCell>
                <Timestamp timestamp={getRunnerStatusesData.getRunnerStatuses.items[0].timestamp} />
              </TableCell>
              <StatusTableCell status={getRunnerStatusesData.getRunnerStatuses.items[0].status} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <History statuses={getRunnerStatusLogData.getRunnerStatusLog.items} />
    </>
  )
}

interface HistoryProps {
  statuses: RunnerStatusLog[]
}

const History = ({ statuses }: HistoryProps) => {
  return (
    <Box>
      <Typography align="center" sx={{ m: 2 }} variant="h5">
        History
      </Typography>
      <TableContainer component={Paper} elevation={2}>
        <Table aria-label="accounts table">
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Source version</TableCell>
              <TableCell>Log Url</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statuses.map((s, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    <Timestamp timestamp={s.timestamp} />
                  </TableCell>
                  <StatusTableCell status={s.status} showProgress={false} />
                  <TableCell>{s.sourceVersion}</TableCell>
                  <LogUrlTableCell logUrl={s.logUrl} />
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
