import React, { ReactNode } from 'react'
import { Container } from '@mui/material'
import AppHeader from './AppHeader'
import AppBreadcrumbs from './AppBreadcrumbs'

interface Props {
  children: ReactNode
}

export default function Layout({ children }: Props) {
  return (
    <>
      <AppHeader />
      <AppBreadcrumbs />
      <Container maxWidth="xl">{children}</Container>
    </>
  )
}
