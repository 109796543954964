import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface Crumb {
  displayName: string
  pathName: string
}

interface State {
  crumbs: Crumb[]
}

const initialState: State = {
  crumbs: [],
}

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState: initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<Crumb[]>) => {
      state.crumbs = action.payload
    },
  },
})

export const { setBreadcrumbs } = breadcrumbsSlice.actions

export default breadcrumbsSlice.reducer
