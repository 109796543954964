import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Breadcrumbs, IconButton, Link } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import { useAppSelector } from 'src/lib/store'

export default function AppBreadcrumbs() {
  const breadcrumbs = useAppSelector((state) => state.breadcrumbs.crumbs)

  return (
    <Breadcrumbs component="nav" aria-label="breadcrumb" sx={{ p: 1 }}>
      <IconButton component={RouterLink} size="small" to="/">
        <HomeIcon fontSize="small" />
      </IconButton>
      {breadcrumbs.map((breadcrumb) => (
        <Link
          key={breadcrumb.pathName}
          component={RouterLink}
          underline="hover"
          color="inherit"
          to={breadcrumb.pathName}
        >
          {breadcrumb.displayName}
        </Link>
      ))}
    </Breadcrumbs>
  )
}
