import React from 'react'
import { createAuthLink, AuthOptions } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { Auth } from 'aws-amplify'

import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client'
import { AppSyncRealTimeSubscriptionConfig } from 'aws-appsync-subscription-link/lib/types'
import { RunnerStatus } from './types'

const appSyncConfig = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT_URL,
  aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'null',
}

const url = appSyncConfig.aws_appsync_graphqlEndpoint

const region = appSyncConfig.aws_appsync_region

const config = {
  url: appSyncConfig.aws_appsync_graphqlEndpoint as string,
  region: appSyncConfig.aws_appsync_region as string,
  auth: {
    type: appSyncConfig.aws_appsync_authenticationType,
    jwtToken: async () => Auth.currentSession().then((session) => session.getIdToken().getJwtToken()),
  } as AuthOptions,
  disableOffline: true,
}

const httpLink = createHttpLink({ uri: url })

const link = ApolloLink.from([
  createAuthLink(config),
  createSubscriptionHandshakeLink({ region, url, auth: config.auth } as AppSyncRealTimeSubscriptionConfig, httpLink),
])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Project: {
        keyFields: ['name'],
      },
      RunnerStatus: {
        keyFields: ['accountId', 'region', 'projectName'],
      },
      RunnerStatusLog: {
        keyFields: ['accountId', 'region', 'projectName', 'timestamp'],
      },
      RunnerStatusList: {
        fields: {
          items: {
            merge(existing: RunnerStatus[], incoming: RunnerStatus[]) {
              return incoming
            },
          },
        },
      },
    },
  }),
})

const ApolloWrapper = ({ children }: { children: React.ReactNode }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloWrapper
