import React, { useEffect } from 'react'
import { useAppDispatch } from 'src/lib/store'
import { setBreadcrumbs } from 'src/lib/store/features/breadcrumbsSlice'
import { Button, CardActions, CardContent, Grid, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

export default function MonitoringPage() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setBreadcrumbs([{ displayName: `Monitoring`, pathName: '/monitoring' }]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container spacing={4} alignContent="center">
      <Grid item xs={12} md={6} sx={{ justifyContent: 'center', display: 'flex' }}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Lambda
          </Typography>
          <Typography paragraph>Monitoring metrics for lambda</Typography>
          <CardActions sx={{ justifyContent: 'center', display: 'flex' }}>
            <Button component={RouterLink} to="/monitoring/lambda" color="secondary" variant="contained">
              Lambda
            </Button>
          </CardActions>
        </CardContent>
      </Grid>
      <Grid item xs={12} md={6} sx={{ justifyContent: 'center', display: 'flex' }}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Api Gateway
          </Typography>
          <Typography paragraph>Monitoring metrics for Api Gateway</Typography>
          <CardActions sx={{ justifyContent: 'center', display: 'flex' }}>
            <Button component={RouterLink} to="/monitoring/api" color="secondary" variant="contained">
              ApiGw
            </Button>
          </CardActions>
        </CardContent>
      </Grid>
    </Grid>
  )
}
