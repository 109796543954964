import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { Amplify } from 'aws-amplify'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Provider } from 'react-redux'
import store from 'src/lib/store'
import ApolloWrapper from 'src/lib/graphql/ApolloWrapper'
import Layout from 'src/components/Layout'
import Alert from 'src/components/Alert'
import HomePage from 'src/pages/Home'
import AccountsPage from 'src/pages/Accounts'
import AccountPage from 'src/pages/Account'
import HistoryPage from 'src/pages/History'
import ProjectsPage from 'src/pages/Projects'
import ProjectPage from 'src/pages/Project'
import NotFoundPage from 'src/pages/404'
import MonitoringPage from './pages/Monitoring'
import './App.css'
import MonitoringLambdaPage from './pages/MonitoringLambda'
import MonitoringApi from './pages/MonitoringApi'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_APPSYNC_REGION,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
})

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(23, 37, 50)',
    },
    secondary: {
      main: 'rgb(57, 179, 155)',
    },
    text: {
      primary: '#000000',
    },
    background: {
      paper: '#f9f9f9',
      default: '#ffffff',
    },
  },
})

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Authenticator hideSignUp>
        <Provider store={store}>
          <ApolloWrapper>
            <Router>
              <Layout>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/monitoring" element={<MonitoringPage />} />
                  <Route path="/monitoring/lambda" element={<MonitoringLambdaPage />} />
                  <Route path="/monitoring/api" element={<MonitoringApi />} />
                  <Route path="/accounts" element={<AccountsPage />} />
                  <Route path="/accounts/:accountId/:region" element={<AccountPage />} />
                  <Route path="/runners/:accountId/:region/:projectName" element={<HistoryPage />} />
                  <Route path="/projects" element={<ProjectsPage />} />
                  <Route path="/projects/:projectName" element={<ProjectPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Layout>
            </Router>
          </ApolloWrapper>
          <Alert />
        </Provider>
      </Authenticator>
    </ThemeProvider>
  )
}
