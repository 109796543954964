import React from 'react'
import InventoryIcon from '@mui/icons-material/Inventory'
import { TableCell, Tooltip } from '@mui/material'

interface Props {
  repositoryUrl: string
}

export default function RepositoryTableCell({ repositoryUrl }: Props) {
  return (
    <TableCell>
      <Tooltip title={repositoryUrl}>
        <InventoryIcon />
      </Tooltip>
    </TableCell>
  )
}
