import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import {
  Box,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { GET_PROJECTS, GetProjectsData } from 'src/lib/graphql/projects'
import { useAppDispatch } from 'src/lib/store'
import { setBreadcrumbs } from 'src/lib/store/features/breadcrumbsSlice'
import { ProjectBase, ProjectType } from 'src/lib/graphql/types'
import { ReactComponent as BackendIcon } from '../images/icons/sitemap.svg'
import { ReactComponent as FrontendIcon } from '../images/icons/laptop-code.svg'
import { ReactComponent as TestIcon } from '../images/icons/vial.svg'

export default function ProjectsPage() {
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setBreadcrumbs([{ displayName: 'Projects', pathName: location.pathname }]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { loading, data, error } = useQuery<GetProjectsData>(GET_PROJECTS)

  if (loading) {
    return <CircularProgress />
  }

  if (!data || error) {
    return <div>Got an error...</div>
  }

  const backendProjects = data?.getProjects?.items?.filter(
    (project: ProjectBase) => project?.type === ProjectType.BACKEND
  )
  const frontendProjects = data?.getProjects?.items?.filter(
    (project: ProjectBase) => project?.type === ProjectType.FRONTEND
  )
  const testProjects = data?.getProjects?.items?.filter((project: ProjectBase) => project?.type === ProjectType.TEST)

  return (
    <>
      <div style={{ display: 'flex', margin: '1rem 0 0' }}>
        <BackendIcon style={{ width: '1.5rem', margin: '0 1rem 0' }} />
        <h2 style={{ margin: '0' }}>Backend</h2>
      </div>
      <Projects projects={backendProjects} />
      <div style={{ display: 'flex', margin: '2rem 0 0' }}>
        <FrontendIcon style={{ width: '1.5rem', margin: '0 1rem 0' }} />
        <h2 style={{ margin: '0' }}>Frontend</h2>
      </div>
      <Projects projects={frontendProjects} />
      <div style={{ display: 'flex', margin: '2rem 0 0' }}>
        <TestIcon style={{ width: '1.5rem', margin: '0 1rem 0' }} />
        <h2 style={{ margin: '0' }}>Test</h2>
      </div>
      <Projects projects={testProjects} />
    </>
  )
}

interface Props {
  projects: ProjectBase[]
}

type Order = 'asc' | 'desc'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return 1
  }
  if (b[orderBy] > a[orderBy]) {
    return -1
  }
  return 0
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const Projects = ({ projects }: Props) => {
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<keyof ProjectBase>('name')

  const createSortHandler = (property: keyof ProjectBase) => {
    return () => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    }
  }

  const sortedProjects = React.useMemo(() => {
    const comparator = getComparator(order, orderBy)
    return [...projects]?.sort((a, b) => {
      return comparator(a, b)
    })
  }, [order, orderBy, projects])

  return (
    <Box sx={{ mt: 3 }}>
      <TableContainer component={Paper} elevation={2}>
        <Table sx={{ minWidth: 300 }} aria-label="projects table" size="small">
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'name' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'desc'}
                  onClick={createSortHandler('name')}
                >
                  Project name
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'type' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'type'}
                  direction={orderBy === 'type' ? order : 'desc'}
                  onClick={createSortHandler('type')}
                >
                  Type
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedProjects.map((r) => (
              <TableRow key={r.name} hover>
                <TableCell sx={{ width: 800 }}>
                  <Link component={RouterLink} to={`/projects/${r.name}`}>
                    {r.name}
                  </Link>
                </TableCell>
                <TableCell>{r.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
