import { gql } from '@apollo/client'
import { Account } from './types'

export interface GetAccountsData {
  getAccounts: {
    items: Account[]
  }
}

export const GET_ACCOUNTS = gql`
  query GetAccounts @client {
    getAccounts {
      items {
        id
        name
        regions
        type
      }
    }
  }
`
