import { gql } from '@apollo/client'
import { ProjectBase } from './types'

export interface GetProjectsData {
  getProjects: {
    items: ProjectBase[]
  }
}

export const GET_PROJECTS = gql`
  query GetProjects {
    getProjects {
      items {
        name
        type
      }
      nextToken
    }
  }
`
