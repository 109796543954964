import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import Api from '../components/monitoring/Api'
import { GetAccountsData, GET_ACCOUNTS } from '../lib/graphql/accounts'
import { useAppDispatch } from '../lib/store'
import { setBreadcrumbs } from '../lib/store/features/breadcrumbsSlice'
import Grid from '@mui/material/Unstable_Grid2'

export default function MonitoringApiPage() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { displayName: `Monitoring`, pathName: '/monitoring' },
        { displayName: `Monitoring - Api`, pathName: '/monitoring/api' },
      ])
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const q = useQuery<GetAccountsData>(GET_ACCOUNTS)
  if (q.loading) return <CircularProgress />
  if (!q.data || q.error) return <div>Got an error...</div>
  return (
    <Grid container spacing={2}>
      <Api accounts={q.data.getAccounts.items} />
    </Grid>
  )
}
