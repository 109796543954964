import { Alert, AlertColor, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'src/lib/store'

const AlertComponent = () => {
  const alert = useAppSelector((state) => state.alert)
  const [open, setOpen] = useState(alert.open)
  const [severity, setSeverity] = useState(alert.severity)
  const [text, setText] = useState(alert.text)

  useEffect(() => {
    setOpen(alert.open)
    setSeverity(alert.severity)
    setText(alert.text)
  }, [alert])

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert severity={severity as AlertColor}>{text}</Alert>
    </Snackbar>
  )
}

export default AlertComponent
