import React, { useEffect, useState } from 'react'
import { Account, AccountType } from 'src/lib/graphql/types'
import Grid from '@mui/material/Unstable_Grid2'
import { GetMetricDataCommandOutput, MetricDataResult } from '@aws-sdk/client-cloudwatch'
import { Typography } from '@mui/material'
import { queryMetrics, Item } from './utils'
import { Area, AreaChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

interface AccountsProps {
  accounts?: Account[]
}
const Lambda: Function = (props: AccountsProps): JSX.Element[] => {
  const [metrics, setMetrics] = useState<GetMetricDataCommandOutput>()
  useEffect(() => {
    const metrics = async () => {
      if (props.accounts) {
        const metricsRes = await queryMetrics({
          accounts: props.accounts,
          metrics: [
            {
              id: '_invocations',
              stats: {
                Metric: { MetricName: 'Invocations', Namespace: 'AWS/Lambda' },
                Stat: 'Sum',
                Period: 60 * 30,
              },
            },
            {
              id: '_errors',
              stats: {
                Metric: { MetricName: 'Errors', Namespace: 'AWS/Lambda' },
                Stat: 'Sum',
                Period: 60 * 30,
              },
            },
          ],
        })
        setMetrics(metricsRes)
      }
    }
    metrics().catch(console.error)
  }, [props.accounts])

  if (!props.accounts)
    return [
      <Grid xs={12}>
        <Item>Missing accounts</Item>
      </Grid>,
    ]

  const sortedAccounts = [...props.accounts].sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })

  const prod = chartByType(sortedAccounts, metrics?.MetricDataResults, AccountType.CUSTOMER_PROD)
  const test = chartByType(sortedAccounts, metrics?.MetricDataResults, AccountType.CUSTOMER_TEST)
  const internal = chartByType(sortedAccounts, metrics?.MetricDataResults, AccountType.INTERNAL)
  const wip = chartByType(sortedAccounts, metrics?.MetricDataResults, AccountType.WIP)

  return [<ChartHeader type={'Production'} key="prod" />]
    .concat(prod)
    .concat(<ChartHeader type={'Test'} key="test" />)
    .concat(test)
    .concat(<ChartHeader type={'Internal'} key="internal" />)
    .concat(internal)
    .concat(<ChartHeader type={'WIP'} key="wip" />)
    .concat(wip)
}

interface ChartItemProp {
  account: Account
  graphData: GraphData[]
}

const ChartItem: Function = (props: ChartItemProp): JSX.Element => {
  return (
    <Item elevation={3}>
      <Typography variant="h6">{props.account.name}</Typography>
      <Typography variant="caption">Invocations last 24h</Typography>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={props.graphData}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#47e0c2" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#47e0c2" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#d88492" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#d88492" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="invocations" stroke="#47e0c2" fillOpacity={1} fill="url(#colorUv)" />
          <Area type="monotone" dataKey="errors" stroke="#d88492" fillOpacity={1} fill="url(#colorPv)" />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="key" />
          <YAxis />
          <Tooltip />
        </AreaChart>
      </ResponsiveContainer>
      <Typography variant="caption">
        <Typography variant="h6">{props.graphData.reduce((a, c) => (c.errors || 0) + a, 0)}</Typography> Errors last 24h
      </Typography>
      <ResponsiveContainer width="100%" height={70}>
        <LineChart data={props.graphData}>
          <Line type="monotone" dataKey="errors" stroke="#ed5f61" dot={false} />
          <YAxis />
          <XAxis dataKey="key" />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </Item>
  )
}

const ChartHeader: React.FC<{ type: string }> = ({ type }): JSX.Element => {
  return (
    <Grid xs={12}>
      <Typography variant="h5" gutterBottom>
        {type}
      </Typography>
    </Grid>
  )
}

const chartByType: Function = (accounts: Account[], metricDataResults: MetricDataResult[], type: AccountType) => {
  return accounts
    .filter((a) => a.type === type)
    .map((a) => {
      const gData = toGraphData(a, metricDataResults)
      return (
        <Grid md={3} xs={12} key={a.id}>
          <ChartItem account={a} graphData={gData} />
        </Grid>
      )
    })
}

interface GraphData {
  errors?: number
  invocations: number
  key: string
}

const toGraphData: Function = (account: Account, metrics: MetricDataResult[]): GraphData[] => {
  let gData: GraphData[] = []
  const accountMetrics = metrics?.filter((m: MetricDataResult) => m.Id?.startsWith(account.name))
  if (!accountMetrics) return gData
  const invocationMetrics = accountMetrics.find((m: MetricDataResult) => m.Label === 'Invocations')
  const errorMetrics = accountMetrics.find((m: MetricDataResult) => m.Label === 'Errors')

  if (invocationMetrics && invocationMetrics.Values && invocationMetrics.Timestamps) {
    for (let i = 0; i < invocationMetrics.Timestamps.length; i++) {
      let error = 0
      if (errorMetrics && errorMetrics.Values) {
        error = errorMetrics?.Values[i]
      }
      gData.push({
        invocations: invocationMetrics.Values[i],
        key: invocationMetrics.Timestamps[i].toString(),
        errors: error,
      })
    }
  }
  return gData
}

export default Lambda
