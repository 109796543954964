import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  LinearProgressProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { Commit, Image } from 'src/lib/graphql/types'
import AutocompleteTag, { TagOption } from 'src/components/AutocompleteTag'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_REPOSITORY, GetRepositoryData, GetRepositoryVars } from 'src/lib/graphql/repositories'
import { startRunner } from 'src/lib/graphql/runner'
import { setAlert } from 'src/lib/store/features/alertSlice'
import { useAppDispatch } from 'src/lib/store'
import Timestamp from './Timestamp'

export interface Account {
  id: string
  name: string
  region: string
}

export function isAccountEquals(a: Account, b: Account): boolean {
  return a.id === b.id && a.region === b.region
}

export interface MultiStartRunnerModalProps {
  open: boolean
  onClose: () => void
  onDeployed: () => void
  projectName: string
  accounts: Account[]
  latestImage: Image
  images?: Image[]
}

export default function MultiStartRunnerModal({
  open,
  onClose,
  onDeployed,
  projectName,
  accounts,
}: MultiStartRunnerModalProps) {
  const dispatch = useAppDispatch()
  const [deploying, setDeploying] = useState(false)
  const [tag, setTag] = useState<TagOption | null>(null)
  const [tags, setTags] = useState<TagOption[]>([])
  const [latestCommit, setLatestCommit] = useState<Commit>()
  const [progress, setProgress] = useState(0)

  const [getRepository, { loading }] = useLazyQuery<GetRepositoryData, GetRepositoryVars>(GET_REPOSITORY)

  useEffect(() => {
    if (open) {
      getRepository({
        fetchPolicy: 'network-only',
        variables: {
          projectName: projectName,
        },
        onCompleted: (data) => {
          const latestTag = data.getRepository.latestMasterCommitHash
          setLatestCommit(data.getRepository.masterCommits.find((mc) => mc.hash === latestTag))
          const tagOptions = data.getRepository.Images.map(
            (i: Image): TagOption => ({
              ...i,
              id: i.tag,
              label: `${i.tag.substring(0, 7)} ${i.tag === latestTag ? '· (master)' : ''} · ${dateTime(i.timestamp)}`,
              deployDate: date(i.timestamp),
            })
          )
          setTag(tagOptions[0])
          setTags(tagOptions)
        },
      })
    } else {
      // wait for the dialog animation to close, and then reset state on "next-tick"
      setTimeout(() => {
        setProgress(0)
        setDeploying(false)
      }, 100)
    }
  }, [open, projectName, getRepository, setProgress, setDeploying])

  const [doStartRunner] = useMutation(startRunner, {
    onCompleted: () => {
      dispatch(
        setAlert({
          open: true,
          severity: 'success',
          text: `Deploy triggered of ${projectName}`,
        })
      )
    },
  })

  const onDeploy = async () => {
    setProgress(0)
    setDeploying(true)

    for (let i = 0; i < accounts.length; i++) {
      setProgress(i + 1)

      const account = accounts[i]

      await doStartRunner({
        variables: {
          accountId: account.id,
          region: account.region,
          projectName: projectName,
          sourceVersion: tag?.tag,
        },
        update: (cache) => {
          cache.modify({
            id: cache.identify(newRunnerStatus(projectName, account)),
            fields: {
              status() {
                return 'IN_PROGRESS'
              },
              timestamp() {
                return new Date().toISOString()
              },
            },
          })
        },
      })
    }

    onDeployed()
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={deploying}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="xl"
    >
      <DialogTitle textAlign="center">{deploying ? 'Deploying project' : 'Deploy project'}</DialogTitle>
      <DialogContentLoading loading={loading}>
        <>
          {deploying && <LinearProgressWithLabel value={progress} max={accounts.length} />}
          {!deploying && (
            <>
              <DialogContentText textAlign="center">
                Select which <b>{projectName}</b> tag to deploy to {accounts.length} account(s)
              </DialogContentText>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Timestamp</TableCell>
                      <TableCell>Hash</TableCell>
                      <TableCell>Comment</TableCell>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={latestCommit?.hash}>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        <Timestamp timestamp={latestCommit?.timestamp ?? ''} />
                      </TableCell>
                      <TableCell>{latestCommit?.hash?.substring(0, 7)}</TableCell>
                      <TableCell style={{ whiteSpace: 'pre-line' }}>{latestCommit?.message}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title={latestCommit?.authorEmail}>
                          <Box component="span">{latestCommit?.authorName}</Box>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DialogContent>
              <AutocompleteTag
                loading={deploying || loading}
                disabled={deploying}
                tag={tag}
                tags={tags}
                onChange={setTag}
              />
            </>
          )}
        </>
      </DialogContentLoading>
      {!loading && !deploying && (
        <DialogActions>
          <Button disabled={deploying} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="success" disabled={!tag} onClick={onDeploy}>
            Deploy
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

interface DialogContentLoaderProps {
  loading: boolean
  children: string | JSX.Element | JSX.Element[]
}

function DialogContentLoading({ loading, children }: DialogContentLoaderProps) {
  return (
    <DialogContent>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </DialogContent>
  )
}

interface LinearProgressWithLabelProps extends LinearProgressProps {
  value: number
  max: number
}

function LinearProgressWithLabel({ value, max, ...props }: LinearProgressWithLabelProps) {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (value > 0) {
      setProgress(Math.min(Math.round((value / max) * 100), 100))
    }
  }, [value, max])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flex: 1, mr: 1 }}>
        <LinearProgress variant="determinate" {...props} value={progress} />
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary">{`${value}/${max}`}</Typography>
      </Box>
    </Box>
  )
}

const date = (timestamp: string) => DateTime.fromISO(timestamp).toLocaleString(DateTime.DATE_MED)
const dateTime = (timestamp: string) => DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_MED)

const newRunnerStatus = (projectName: string, a: Account) => ({
  __typename: 'RunnerStatus',
  accountId: a.id,
  region: a.region,
  projectName: projectName,
})
