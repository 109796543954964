import React from 'react'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { TableCell } from '@mui/material'

interface Props {
  logUrl?: string
}

export default function LogUrlTableCell({ logUrl }: Props) {
  return (
    <TableCell>
      {logUrl ? (
        <a target="_blank" href={logUrl} rel="noopener noreferrer">
          <TextSnippetIcon />
        </a>
      ) : (
        ''
      )}
    </TableCell>
  )
}
