import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { alpha } from '@mui/material/styles'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  GET_RUNNER_STATUSES,
  GetRunnerStatusesData,
  GetRunnerStatusesVars,
  RUNNER_STATUS_UPDATED,
  RunnerStatusUpdatedData,
} from 'src/lib/graphql/runnerStatuses'
import { useAppDispatch } from 'src/lib/store'
import { setBreadcrumbs } from 'src/lib/store/features/breadcrumbsSlice'
import Timestamp from 'src/components/Timestamp'
import StatusTableCell from 'src/components/tablecells/StatusTableCell'
import DiffModal from 'src/components/DiffModal'
import { AccountType, RunnerStatus } from 'src/lib/graphql/types'
import MultiStartRunnerModal, { Account, isAccountEquals } from 'src/components/MultiStartRunnerModal'
import { ReactComponent as AwsIcon } from 'src/images/icons/aws.svg'

export default function ProjectPage() {
  const params = useParams()
  const projectName = params.projectName as string

  const dispatch = useAppDispatch()
  const location = useLocation()
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { displayName: 'Projects', pathName: '/projects' },
        { displayName: projectName, pathName: location.pathname },
      ])
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    subscribeToMore,
    loading: getRunnerStatusesLoading,
    data: getRunnerStatusesData,
    error: getRunnerStatusesError,
  } = useQuery<GetRunnerStatusesData, GetRunnerStatusesVars>(GET_RUNNER_STATUSES, {
    variables: {
      projectName: projectName,
    },
  })

  subscribeToMore<RunnerStatusUpdatedData>({
    document: RUNNER_STATUS_UPDATED,
    variables: { projectName: projectName },
  })

  if (getRunnerStatusesLoading) return <CircularProgress />
  if (!getRunnerStatusesData || getRunnerStatusesError) return <div>Got an error...</div>

  return (
    <>
      <Typography variant="h4" component="h1" sx={{ textTransform: 'capitalize' }}>
        {projectName.replace('-', ' ')}
      </Typography>

      <ProjectTable projectName={projectName} runnerStatuses={getRunnerStatusesData.getRunnerStatuses.items} />
    </>
  )
}

interface ProjectTableProps {
  projectName: string
  runnerStatuses: RunnerStatus[]
}

const ProjectTable = ({ projectName, runnerStatuses }: ProjectTableProps) => {
  const sections: Sections = {
    [AccountType.WIP]: [],
    [AccountType.INTERNAL]: [],
    [AccountType.CUSTOMER_TEST]: [],
    [AccountType.CUSTOMER_PROD]: [],
  }

  const sortedRunnerStatuses = [...runnerStatuses].sort((a, b) => {
    const accountNameA = a.Account.name
    const accountNameB = b.Account.name

    if (accountNameA < accountNameB) {
      return -1
    }

    if (accountNameA > accountNameB) {
      return 1
    }

    return 0
  })

  sortedRunnerStatuses.reduce((acc, curr) => {
    acc[curr.Account.type].push(curr)
    return acc
  }, sections)

  return (
    <Box>
      <StatusesTable title="Internal" projectName={projectName} runnerStatuses={sections.INTERNAL} />
      <StatusesTable title="Test" projectName={projectName} runnerStatuses={sections.CUSTOMER_TEST} />
      <StatusesTable title="Production" projectName={projectName} runnerStatuses={sections.CUSTOMER_PROD} />
      <StatusesTable title="WIP" projectName={projectName} runnerStatuses={sections.WIP} />
    </Box>
  )
}

interface StatusesTableProps {
  title: string
  projectName: string
  runnerStatuses: RunnerStatus[]
}

function StatusesTable({ title, projectName, runnerStatuses }: StatusesTableProps) {
  const [selected, setSelected] = useState<Account[]>([])
  const [deployModal, setDeployModal] = useState(false)
  const openDeployModal = () => setDeployModal(true)
  const closeDeployModal = () => setDeployModal(false)

  if (!runnerStatuses.length) {
    return null
  }

  const numSelected = selected.length
  const indeterminate = numSelected > 0 && numSelected < runnerStatuses.length
  const isSelected = (acc: Account) => selected.findIndex((a) => isAccountEquals(acc, a)) !== -1

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && !indeterminate) {
      setSelected(
        runnerStatuses
          .filter((rs) => rs.status !== 'IN_PROGRESS')
          .map((rs): Account => ({ id: rs.accountId, name: rs.Account.name, region: rs.region }))
      )
      return
    }
    setSelected([])
  }

  const onSelectRow = (selectedAccount: Account) => {
    if (isSelected(selectedAccount)) {
      setSelected([...selected].filter((a) => !isAccountEquals(a, selectedAccount)))
      return
    }

    setSelected([...selected, selectedAccount])
  }

  const onDeployed = () => {
    setSelected([])
  }

  const image = {
    tag: 'string',
    digest: 'string',
    timestamp: 'string',
  }

  return (
    <Paper sx={{ my: 3 }} elevation={2}>
      <MultiStartRunnerModal
        open={deployModal}
        projectName={projectName}
        accounts={selected}
        latestImage={image}
        images={[image]}
        onClose={closeDeployModal}
        onDeployed={onDeployed}
      />
      <SectionToolbar title={title} numSelected={numSelected} onDeployClick={openDeployModal} />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={indeterminate}
                  checked={runnerStatuses.length > 0 && numSelected === runnerStatuses.length}
                  onChange={onSelectAll}
                  inputProps={{ 'aria-label': 'select all' }}
                />
              </TableCell>
              <TableCell>Account name</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>Source revision deployed </TableCell>
              <TableCell>Latest commit (master)</TableCell>
              <TableCell>Log url</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell colSpan={2}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {runnerStatuses.map((rs) => {
              const a = newAccount(rs)
              return (
                <StatusesTableRow
                  key={`${rs.accountId},${rs.projectName}`}
                  selected={isSelected(a)}
                  onSelect={() => onSelectRow(a)}
                  runnerStatus={rs}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

type Sections = { [key in keyof typeof AccountType]: RunnerStatus[] }

interface StatusesTableRowProps {
  runnerStatus: RunnerStatus
  selected: boolean
  onSelect: (rs: RunnerStatus) => void
}

function StatusesTableRow({ runnerStatus: rs, selected, onSelect }: StatusesTableRowProps) {
  const [openDiffModal, setOpenDiffModal] = useState(false)
  const handleOpenDiffModal = () => setOpenDiffModal(true)
  const handleCloseDiffModal = () => setOpenDiffModal(false)

  const accountName = rs.Account.name
  const latestMasterCommitHash = rs.Project.Repository.latestMasterCommitHash ?? ''
  const masterRevDeployed = latestMasterCommitHash === rs.sourceVersion
  const inProgress = rs.status === 'IN_PROGRESS'

  const onRowClick = () => {
    if (inProgress) return
    onSelect(rs)
  }

  return (
    <TableRow hover role="checkbox" selected={selected} onClick={onRowClick} sx={{ cursor: 'pointer' }}>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          disabled={inProgress}
          checked={selected}
          inputProps={{ 'aria-labelledby': accountName }}
        />
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Link component={RouterLink} to={`/accounts/${rs.accountId}/${rs.region}`}>
          {accountName} ({rs.accountId})
        </Link>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{rs.region}</TableCell>
      <TableCell>
        <Tooltip title={rs.sourceVersion ? rs.sourceVersion : ''}>
          <span>{rs.sourceVersion && rs.sourceVersion.substring(0, 7)}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={latestMasterCommitHash}>
          <span>{latestMasterCommitHash.substring(0, 7)}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <a href={rs.logUrl} target="_blank" rel="noreferrer">
          <AwsIcon style={{ width: '1.8rem' }} />
        </a>
      </TableCell>
      <StatusTableCell status={rs.status} />
      <TableCell>
        <Timestamp timestamp={rs.timestamp} />
      </TableCell>
      <TableCell onClick={(e: React.MouseEvent) => e.stopPropagation()}>
        <DiffModal
          open={openDiffModal}
          handleClose={handleCloseDiffModal}
          deployedCommit={rs.sourceVersion}
          repository={rs.Project.Repository}
        />
        <Button disabled={masterRevDeployed} size="small" variant="contained" onClick={handleOpenDiffModal}>
          Diff
        </Button>
      </TableCell>
      <TableCell onClick={(e: React.MouseEvent) => e.stopPropagation()}>
        <Button
          component={RouterLink}
          to={`/runners/${rs.accountId}/${rs.region}/${rs.projectName}`}
          size="small"
          variant="outlined"
          color="secondary"
          sx={{ m: 1 }}
        >
          History
        </Button>
      </TableCell>
    </TableRow>
  )
}

interface SectionToolbarProps {
  title: string
  numSelected: number
  onDeployClick: () => void
}

function SectionToolbar({ title, numSelected, onDeployClick }: SectionToolbarProps) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme: any) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="h2">
          {title}
        </Typography>
      )}

      {numSelected > 0 && (
        <Button variant="contained" color="success" onClick={onDeployClick}>
          Deploy
        </Button>
      )}
    </Toolbar>
  )
}

const newAccount = (rs: RunnerStatus): Account => ({
  id: rs.accountId,
  name: rs.Account.name,
  region: rs.region,
})
